.navbar {
  z-index: 99;
}

.banner {
  height: 75vh !important;
  background-position: center 50px !important;
}

.menu-item-box {
  border: 2px solid #000000b3;
  padding: 6px;
  border-radius: 3px;
}

.nav-item {
  margin-right: 5px;
}

.color-yellow {
  color: #ffeb3b;
}

.color-white {
  color: #ffffff;
}

.color-green {
  color: #00c851;
}

.color-grey {
  color: #9e9e9e;
}

.color-black {
  color: #000000;
}

.bg-color-off-white {
  background-color: #fffff2 !important;
}

.custom-navbar .nav-item a {
  color: #000000;
  font-weight: normal;
}

.custom-navbar .nav-item a:hover {
  color: #505050;
}

.available:hover {
  background-color: #0ba71e !important;
}

.available-admin:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.table-border-bottom-dotted {
  border-bottom-style: dotted;
  border-color: #d0d0d0;
}

.booked:hover {
  background-color: #1075c6 !important;
}

.booked-recurring:hover {
  background-color: rgb(69 155 72 / 70%) !important;
}

.search-field-container {
  background-color: #ffeb3b59;
  padding: 9px;
  border-radius: 3px;
}

.booking-modal .modal .modal-fluid,
.fix-fluid-modal .modal .modal-fluid {
  width: 90%;
}

.fix-size-calendar-timeslot {
  width: 115px !important;
}

.required:before {
  content: "* ";
  color: red;
}

.pricing-container {
  position: absolute;
  bottom: 0px;
  right: 0;
  margin-right: 31px;
  width: 90%;
}

.price-change-msg {
  text-align: center;
}

.pricing-table {
  width: 175px;
  border-top-style: outset;
  border-bottom-style: outset;
  border-width: 2px;
  border-color: #9b9a9aba;
}

.modal-child-popup {
  position: absolute;
  right: 20%;
  left: 20%;
  top: 20%;
  bottom: 20%;
  min-height: 395px;
  z-index: 99;
}

.was-validated .error input[type="text"] {
  border-color: #dc3545;
}

.booking-modal .tab-content {
  padding-top: 0px;
}

.booking-modal .md-pills .nav-link {
  text-align: right;
}

.border-right {
  border-right: 1px solid #e3e1e1;
}

.text-align-right {
  text-align: right;
}

.input-label-bold label {
  font-weight: bold;
}

.coupon-section .md-form,
.custom-select-date .md-form,
.calendarHeader .md-form {
  margin-bottom: 0px;
}

.autoComplete .menu {
  position: absolute;
  background: #efefef;
  z-index: 3;
  max-height: 300px;
  overflow-y: auto;
  width: 90%;
  padding: 10px;
}

.autoComplete input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autoComplete input:focus:not([readonly]) {
  border-color: #75c1f0;
  box-shadow: 0 1px 0 0 #75c1f0;
}

.autoComplete .menu .item.item-highlighted {
  background: #d2d2d2;
}

.autoComplete .menu .item {
  margin: 5px;
}

.autoComplete .menu .item:hover {
  cursor: pointer;
}

.remove-margin-md-form .md-form,
.generic-search .md-form {
  margin: 0;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.remove-margin-tb .md-form.md-outline {
  margin-top: 0;
  margin-bottom: 0;
}

.content-min-height {
  min-height: auto;
}

.padding-left-37 {
  padding-left: 36px;
}

.padding-left-3 {
  padding-left: 3px;
}

span.badge {
  margin-right: 5px;
}

.info-label {
  font-size: small;
  font-style: italic;
  color: darkgrey;
}

.custom-label {
  font-size: small;
  color: gray;
}

.tile-image-size {
  width: 100%;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.custom-input {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom .md-form {
  margin-top: 0;
  margin-bottom: 0;
}

a.nav-item-level-2 {
  line-height: 15px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  line-break: anywhere;
  padding-right: 10px;
}

a.nav-item-level-3 {
  padding-left: 65px !important;
  line-height: 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  line-break: anywhere;
}

.loading {
  height: 0;
  width: 0;
  position: absolute;
  left: 50%;
  top: 35%;
  z-index: 9999;
}

/* .weeklycalendar thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: -1px;
  z-index: 11;
}

.admin-page .weeklycalendar thead tr:nth-child(1) th {
  top: 48px;
}  */

.admin-page .modalCalendar .table-responsive {
  max-height: 700px;
  overflow-y: auto;
}

.admin-page .modalCalendar .weeklycalendar thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0px;
  z-index: 12;
}

.admin-page .modalCalendar .weeklycalendar thead tr:nth-child(2) th {
  background: white;
  position: sticky;
  top: 162px;
  z-index: 11;
  box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
}

.admin-page .weeklycalendar thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 48px;
  z-index: 12;
}

.admin-page .weeklycalendar thead tr:nth-child(2) th {
  background: white;
  position: sticky;
  top: 189px;
  z-index: 11;
  box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
}

.admin-page .weeklycalendar.mycalendar thead tr:nth-child(2) th {
  background: white;
  position: sticky;
  top: 154px;
  z-index: 11;
  box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
}

.weeklycalendar thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 12;
}

.weeklycalendar thead tr:nth-child(2) th {
  background: white;
  position: sticky;
  top: 139px;
  z-index: 11;
  box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .custom-col {
    width: 100%;
  }

  .banner {
    height: 80vh !important;
  }

  .admin-page .weeklycalendar thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 48px;
    z-index: 12;
  }

  .admin-page .weeklycalendar thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 240px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .admin-page .weeklycalendar.ct thead tr:nth-child(2) th,
  .admin-page .weeklycalendar.st thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 325px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .admin-page .weeklycalendar.ct.st thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 400px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .admin-page .modalCalendar .weeklycalendar thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0px;
    z-index: 12;
  }

  .admin-page .modalCalendar .weeklycalendar thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 205px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .weeklycalendar thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0;
    z-index: 12;
  }

  .weeklycalendar thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 182px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .weeklycalendar.ct thead tr:nth-child(2) th,
  .weeklycalendar.st thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 262px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .weeklycalendar.ct.st thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 341px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .custom-col {
    width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-col {
    flex-basis: auto;
    flex-grow: 1;
    max-width: 100%;
  }

  .admin-page .weeklycalendar thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 48px;
    z-index: 12;
  }

  .admin-page .weeklycalendar thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 240px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .admin-page .weeklycalendar.ct thead tr:nth-child(2) th,
  .admin-page .weeklycalendar.st thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 325px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .admin-page .weeklycalendar.ct.st thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 400px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .admin-page .modalCalendar .weeklycalendar thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0px;
    z-index: 12;
  }

  .admin-page .modalCalendar .weeklycalendar thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 225px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .weeklycalendar thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0;
    z-index: 12;
  }

  .weeklycalendar thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 163px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .weeklycalendar.ct thead tr:nth-child(2) th,
  .weeklycalendar.st thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 163px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }

  .weeklycalendar.ct.st thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 164px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .custom-col {
    flex-basis: auto;
    flex-grow: 1;
    max-width: 100%;
  }

  .admin-page .modalCalendar .weeklycalendar thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0px;
    z-index: 12;
  }

  .admin-page .modalCalendar .weeklycalendar thead tr:nth-child(2) th {
    background: white;
    position: sticky;
    top: 205px;
    z-index: 11;
    box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .custom-col {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .tile-image-size {
    width: 320px;
    height: 262px;
  }
}

main {
  margin: 0 2% !important;
  padding-top: 2rem !important;
}

.cointainer-fluid {
  padding: 0 !important;
}

.pagination {
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff !important;
}
.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.overlay-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: "rgba(255, 255, 255, 0.78)";
  opacity: "0.8";
  z-index: "999999";
  height: "100%";
  width: "100%";
}

.organisation-settings {
  padding: 0 1rem;
}

.organisation-attributes {
  padding: 0;
}

/* .weeklycalendar thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: -1px;
  z-index: 11;
}

.admin-page .weeklycalendar thead tr:nth-child(1) th {
  top: 48px;
} */

/* .weeklycalendar thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 48px;
  z-index: 11;
}

.weeklycalendar thead tr:nth-child(2) th {
  background: white;
  position: sticky;
  top: 189px;
  z-index: 11;
  box-shadow: inset 0 1px 0 #c6c6c6a3, inset 0 0px 0 #515151;
} */

/* thead tr.first th {
  position: sticky;
  top: 48px;
  z-index: 11;
}

thead tr.second th {
  position: sticky;
  top: 70px;
  z-index: 11;
} */

.weeklycalendar tbody td,
.weeklycalendar-mobile tbody td {
  vertical-align: middle;
  position: relative;
}

.weeklycalendar .slotIconContainer,
.weeklycalendar-mobile .slotIconContainer {
  position: absolute;
  top: 0px;
  right: -4px;
  z-index: 10;
}

.weeklycalendar .slotIconContainer .badge,
.weeklycalendar-mobile .slotIconContainer .badge {
  font-size: 14px;
}

.scrollable {
  overflow-y: auto;
}

.goToTodayCont .goToToday {
  background-color: #2196f3 !important;
  margin-right: 0;
}

.grey-skin .side-nav .social {
  border-bottom: 1px solid rgb(185 185 185) !important;
}

.sticky .search-field-container {
  background-color: #dadadaf2;
}

.do-scroll-y {
  overflow-y: scroll;
  max-height: 300px;
}

.mobile-view .do-scroll-y {
  overflow-y: scroll;
  max-height: 150px;
}

.modalTCLink {
  color: #2196f3;
  text-decoration: underline;
  font-size: 17px;
  cursor: pointer;
  font-weight: 400;
}

.testing {
  z-index: 7000;
}

.react-datepicker-wrapper {
  width: 100%;
  margin-top: 3px;
}

.bookingForm .modal-title {
  width: 100%;
}

.bookingForm .modal-title .md-form {
  margin-bottom: 0;
  margin-top: 1.5rem;
}

.bookingForm .modal-title .md-form input[type="text"],
.custom-modal-title .md-form input[type="text"] {
  border-bottom: none;
}

.bookingForm {
  padding-bottom: 0;
}

.generic-search .generic-search-field {
  padding: 0.2rem 0 0.4rem 0;
  width: 210px !important;
  color: #fff;
}

.generic-search-result th {
  text-align: left;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.075);
  text-transform: capitalize;
}

.generic-search-result td {
  padding: 10px !important;
}

.generic-search-result table tr td:nth-child(2) {
  width: 140px;
}

.generic-search-result .spinner-border {
  float: right;
  color: #6f6f6f;
  /* font-size: 5px; */
  /* height: 29px; */
  width: 1.5rem;
  height: 1.5rem;
}

.testing td {
  border: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.testing tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0);
}

/* Small devices */
.calendarHeader .dateContainer .dateNav {
  padding: 0;
  width: 1px;
  padding-top: 3px;
}

.calendarHeader .dateContainer .dateNav button {
  height: 38px;
}
.calendarHeader .dateContainer .datePicker {
  padding: 0;
}

.mobile-view .fixed-action-btn ul {
  bottom: 1px;
}

.mobile-view .fixed-action-btn {
  overflow: visible;
  height: auto;
}

.mobile-view .fa-calendar-check:before {
  content: "Today";
  font-size: 14px;
  font-family: "Roboto";
}

.mobile-view .fixed-action-btn ul li {
  margin-left: 23px;
}

.mobile-view .action-tooltips {
  position: absolute;
  left: -60px;
  top: 13px;
  width: 80px;
  z-index: 2;
  background: #413e3eb5;
  color: white;
  font-size: small;
  font-weight: bold;
  border-radius: 4px;
}

.mobile-view .modal-backdrop {
  width: 100%;
  height: 100%;
}

.mobile-view .booking-modal .modal-fluid {
  width: 95%;
}

.mobile-view .modal-dialog {
  margin: auto;
}

.mobile-view .pricing-container {
  margin-right: 0;
}

.navbar-toggler-icon {
  background-image: url(/images/menu_grey_192x192.png);
  cursor: pointer;
}

.child-popup .modal.fade.top.show {
  z-index: 1051;
}

.sidepanel {
  position: fixed;
  z-index: 50;
  height: 100%;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-left: 36px;
}

.sidepanel .detailsContainer {
  background: #f5f5f5;
  height: 100%;
  border-left: 1px solid #7070703d;
  padding-top: 55%;
  overflow-y: auto;
}

.sidepanel .showPanel {
  position: absolute;
  left: 1px;
  transition: 0.3s;
  padding: 9px;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border-radius: 5px 0 0 5px;
  background-color: #00c851;
  writing-mode: vertical-rl;
  text-orientation: upright;
  top: 40%;
  cursor: pointer;
  text-transform: uppercase;
  /* -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite; */
}

/* @-webkit-keyframes glowing {
  0% {
    background-color: #00c851;
    -webkit-box-shadow: 0 0 3px #00c851;
  }
  50% {
    background-color: #09d85d;
    -webkit-box-shadow: 0 0 10px #09d85d;
  }
  100% {
    background-color: #00c851;
    -webkit-box-shadow: 0 0 3px #00c851;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #00c851;
    -moz-box-shadow: 0 0 3px #00c851;
  }
  50% {
    background-color: #09d85d;
    -moz-box-shadow: 0 0 10px #09d85d;
  }
  100% {
    background-color: #00c851;
    -moz-box-shadow: 0 0 3px #00c851;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #00c851;
    box-shadow: 0 0 3px #00c851;
  }
  50% {
    background-color: #09d85d;
    box-shadow: 0 0 10px #09d85d;
  }
  100% {
    background-color: #00c851;
    box-shadow: 0 0 3px #00c851;
  }
}

@keyframes glowing {
  0% {
    background-color: #00c851;
    box-shadow: 0 0 3px #00c851;
  }
  50% {
    background-color: #09d85d;
    box-shadow: 0 0 10px #09d85d;
  }
  100% {
    background-color: #00c851;
    box-shadow: 0 0 3px #00c851;
  }
} */

.sidepanel .detailsContainer table th,
.sidepanel .detailsContainer table td {
  font-weight: bold;
  text-align: center;
}

.sidepanel .detailsContainer table th {
  padding: 3px;
  background: #dedede;
}

.sidepanel .detailsContainer table {
  margin-bottom: 0;
}

.sidepanel .detailsContainer h6 {
  text-align: center;
  font-weight: bold;
  background: #d0d0d0;
  padding: 2px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.sidepanel .detailsContainer table tr td:nth-child(3) {
  color: #00c851;
}

.pricing-note {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-style: italic;
}

.weeklycalendar-mobile .pricing-note {
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  font-style: italic;
  margin-top: 45px;
  position: relative;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.with-antd .ant-input {
  width: auto;
}

.with-antd .rule-remove,
.ruleGroup-remove {
  float: right;
}

.ruleGroup {
  padding: 0.5rem;
  border: 1px solid #7f81a2;
  border-radius: 4px;
  background: rgba(0, 75, 183, 0.2);
}

.with-antd .ruleGroup-addRule,
.ruleGroup-addGroup,
.ruleGroup-notToggle {
  margin-left: 0.5rem;
}

.with-default .ruleGroup .ruleGroup-combinators.betweenRules {
  margin-top: 0.5rem;
}

.ruleGroup .rule,
.ruleGroup .ruleGroup s {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

@media (min-width: 1200px) {
  .admin-page .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
}

.ruleGroup .ruleGroup-combinators.betweenRules {
  margin-top: 0.5rem;
}

.ruleGroup .rule,
.ruleGroup .ruleGroup {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.modal.show {
  overflow-y: auto;
}

.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #46aded;
}

.nested-table table {
  width: 100%;
}

.nested-table table tr {
  background-color: transparent !important;
}

.nested-table td:first-child,
.nested-table td:nth-of-type(2) {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.nested-table td {
  font-size: 14px;
}

.nested-table td,
.nested-table th {
  padding: 10px !important;
}

.table-th-100-px tr th {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}
.between {
  width: 30% !important;
}

.undo-text .fa-undo:before {
  content: "Undo";
  font-family: "Roboto";
  text-transform: capitalize;
}

.change-text .fa-undo:before {
  content: "Change";
  font-family: "Roboto";
  text-transform: capitalize;
}

.popup-status .td-popup {
  position: relative;
}

.popup-status .td-popup .td-popup-container {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  padding: 3px;
  color: black;
  top: -31px;
  right: -1px;
  background: #fffdeb;
  border-radius: 7px;
  border: 1px solid #ccc9c9;
}

.popup-status .td-popup:hover span.td-popup-container {
  display: block;
}

.TCTable tr {
  border-bottom: 1px solid;
}

.table-no-side-border td {
  border-left: 0;
  border-right: 0;
}

.tr-fix-height {
  height: 78px;
}
